@font-face {
  font-family: 'Titillium Web';
  src: url('../fonts/TitilliumWeb-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizelegibility;
}

html {
  width: 100%;
  height: 100%;
}

body {
  font-family: 'Titillium Web', sans-serif;
  margin: 0;
  width: 100%;
  height: 100%;
}

.overflow-x-auto {
  overflow: auto hidden;
}

.btn-column {
  width: 100px;
}
.logo-text {
  font-size: 30px;
  font-weight: 900;
  color: #000000;
}

.logo-text.logged-in {
  margin-left: 20px;
}

.logo-text:hover {
  text-decoration: none !important;
  color: #000000;
}

.logo-text.active {
  text-decoration: none !important;
  color: #000000;
}

.toggler-container {
  height: 25px;
  width: 60px;
  border: 2px solid #F2F2F2;
  background-color: #F2F2F2;
  border-radius: 25% / 50%;
  position: relative;
  cursor: pointer;
  transition-property: background-color;
  transition-duration: 0.5s;
}

.toggler-container.center-align {
  margin-left: auto;
  margin-right: auto;
}
.toggler-container.right-align {
  margin-left: auto;
}

.toggler-container[disabled] {
  cursor: initial;
  opacity: 0.75;
}

.toggler-container .slider-container {
  height: 0;
  width: calc(40% - 4px);
  border: 16px solid #FFFFFF;
  position: absolute;
  top: -5px;
  left: -7px;
  box-shadow:
    inset 0 -3em 3em rgb(0 0 0 / 10%),
    0 0  0 2px rgb(0 0 0 / 10%),
    0.3em 0.3em 1em rgb(0 0 0 / 30%);
  border-radius: 50%;
  transition-property: left;
  transition-duration: 0.5s;
}

.toggler-container.active .slider-container {
  top: -5px;
  left: calc(50% + 2px);
}

.toggler-container.active .toggler-state {
  color: #FFFFFF;
  float: left;
  margin-left: 4px;
  margin-top: 2px;
  font-size: 12px;
}

.toggler-container .toggler-state {
  color: #A9A9A9;
  margin-right: 4px;
  margin-top: 3px;
  float: right;
  font-size: 12px;
}

.toggler-container.active {
  background-color: #223679;
}

.toggler-container.active .slider {
  left: calc(60% + 2.5px);
  background-color: #223679;
}

.toggler-container .slider {
  position: absolute;
  width: calc(42% - 4px);
  height: calc(100% - 2px);
  border-radius: 50%;
  top: 0;
  left: -1px;
  background-color: #223679;
  transition-property: left;
  transition-duration: 0.5s;
}

.toggler-container[disabled] .slider {
  background-color: #727EA9;
}

.navBar {
  height: 60px;
  background-color: #FFFFFF;
  border-bottom: 1px solid #CCCCCC;
  position: fixed !important;
  top: 0;
  width: 100%;
  z-index: 9;
}

.navBar .navBar-inner {
  width: 100%;
}

.navBar .burger-container {
  display: inline-block;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 13px;
}

.navBar .burger-content {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  top: 0;
  left: -100%;
  border: 0;
  border-radius: 0;
  overflow-x: hidden;
  height: 100%;
  padding: 8px 20px;
  background-color: #FFFFFF;
  transition: 0.4s;
  z-index: 1;
}

.navBar .burger-content.open {
  left: 0;
}

.navBar .burger-content .logo {
  margin-bottom: 10px;
}

.navBar .burger-content .logo .logo-text {
  margin-left: 0;
}

.navBar .burger-content .menu {
  overflow-y: scroll;
  border-top: 1px solid #CCCCCC;
}

.navBar .burger-content .menu .header {
  background-color: #223679;
  color: #FFFFFF;
  padding: 10px 7px 10px 20px;
}

.navBar .burger-content .menu .menuItem {
  padding: 10px 7px 10px 20px;
  text-decoration: none;
  color: #414141;
}

.navBar .burger-content .menu .menuItem.active {
  background-color: #F1F1F1;
  color: #223679;
  font-weight: 700;
}

.navBar .burger-content .logout-item {
  border-top: 1px solid #223679;
}

.navBar .burger-content .logout-item .menuItem {
  display: flex;
  padding: 10px 20px;
  color: #414141;
  text-decoration: none;
}

.navBar .burger-content .menuItem:hover,
.navBar .burger-content .menuItem:focus {
  background-color: #F1F1F1;
  cursor: pointer;
}

.navBar .burger-container .burger-bar1,
.navBar .burger-container .burger-bar2,
.navBar .burger-container .burger-bar3 {
  width: 30px;
  height: 3px;
  background-color: #223679;
  margin: 6px 0;
  transition: 0.4s;
}

.navBar .change .burger-bar1 {
  transform: rotate(-45deg) translate(-9px, 3px);
}

.navBar .change .burger-bar2 {
  opacity: 0;
}

.navBar .change .burger-bar3 {
  transform: rotate(45deg) translate(-9px, -4px);
}

.icon {
  min-width: 70px;
}

.navBar .group-icons {
  margin-right: 15px;
  margin-left: 15px;
}

.navBar .group-icons > div {
  margin-right: 15px;
  cursor: pointer;
}

.navBar .image {
  margin-left: 15px;
}

.navBar .group-icons a {
  text-decoration: none;
  color: #414141;
}

.navBar > a {
  margin-left: 10px;
  margin-right: 10px;
}

.content {
  min-height: 100vh;
  position: relative;
}

.sidebar {
  width: 220px;
  background-color: #FFFFFF;
  padding-top: 60px;
  position: sticky;
  top: 0;
  left: 0;
}

.module {
  background-color: #E0E0E7;
  z-index: 1;
  height: 111%;
}

.sidebar .header {
  background-color: #223679;
  color: #FFFFFF;
  padding: 10px 7px 10px 20px;
}

.sidebar .menublock {
  padding-bottom: 10px;
}

.sidebar .list-menus {
  font-size: 14px;
}

.sidebar .menuItem {
  padding: 10px 7px 10px 20px;
  text-decoration: none;
  color: #414141;
}

.sidebar .menuItem.active {
  background-color: #F1F1F1;
  color: #223679;
  font-weight: bold;
}

.content .panel-default > .panel-heading {
  color: #FFFFFF;
  background-color: #223679;
  border-color: #DDDDDD;
}

.content .panel-default {
  min-height: 170px;
}

.panel {
  margin-bottom: 10px !important;
}

.form-control,
.Select-control {
  height: 44px;
  min-height: 44px;
  max-width: 100%;
}

.Select-placeholder,
.Select--single > .Select-control .Select-value {
  line-height: 43px;
}

.Select .Select-menu-outer {
  z-index: 5 !important;
}

.Select-input[aria-disabled="true"] {
  min-width: 25px;
}

.btn {
  padding: 11px 12px;
}

.btn.defaultBtn {
  color: #FFFFFF;
  text-transform: uppercase;
  background-color: #63D311;
  outline: 0;
}

.btn.defaultBtn:hover,
.btn.defaultBtn:focus {
  background-color: #5ABD11;
}

.btn.btn-secondary {
  background-color: #CCCCCC;
  color: #FFFFFF;
}

.btn.btn-secondary:hover,
.btn.btn-secondary:focus {
  color: #333333;
  background-color: #E6E6E6;
  border-color: #ADADAD;
}

/* POPUP */
.popup-window {
  color: #31708F;
  overflow-wrap: break-word;
  background-color: #D9EDF7;
  border-color: #BCE8F1;
  margin-top: 70px;
  min-width: 300px;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 15px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%) !important;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #F9F9F9;
}

.table-striped > tbody > tr:nth-of-type(even) {
  background-color: #FFFFFF;
}

/**
 * Date time picker
 *
 */

.rdt {
  position: relative;
}

.rdt .form-control {
  background-color: #FFFFFF;
}

.rdt .form-control[disabled] {
  background-color: #F9F9F9;
}

.rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #FFFFFF;
  box-shadow: 0 1px 3px rgb(0 0 0 / 10%);
  border: 1px solid #F9F9F9;
}

.rdtOpen .rdtPicker {
  display: block;
  right: 0;
}

.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
}

.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}

.rdtPicker td {
  cursor: pointer;
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #EEEEEE;
  cursor: pointer;
}

.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999;
}

.rdtPicker td.rdtToday {
  position: relative;
}

.rdtPicker td.rdtToday::before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428BCA;
  border-top-color: rgb(0 0 0 / 20%);
  position: absolute;
  bottom: 4px;
  right: 4px;
}

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #428BCA;
  color: #FFFFFF;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 25%);
}

.rdtPicker td.rdtActive.rdtToday::before {
  border-bottom-color: #FFFFFF;
}

.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}

.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker th {
  border-bottom: 1px solid #F9F9F9;
}

.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default;
}

.rdtPicker th.rdtSwitch {
  width: 100px;
}

.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */   /* Chrome/Safari/Opera */      /* Firefox */       /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker thead tr:first-child th {
  cursor: pointer;
}

.rdtPicker thead tr:first-child th:hover {
  background: #EEEEEE;
}

.rdtPicker tfoot {
  border-top: 1px solid #F9F9F9;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}

.rdtPicker button:hover {
  background-color: #EEEEEE;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}

td.rdtMonth:hover,
td.rdtYear:hover {
  background: #EEEEEE;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters > div {
  float: left;
}

.rdtCounter {
  height: 100px;
  width: 40px;
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */   /* Chrome/Safari/Opera */      /* Firefox */       /* Internet Explorer/Edge */
  user-select: none;
}

.rdtCounter .rdtBtn:hover {
  background: #EEEEEE;
}

.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

.rdtTime td {
  cursor: default;
}

.react-datepicker-popper {
  z-index: 2;
  min-width: 282px;
}

.react-datepicker {
  border-color: #F9F9F9;
  box-shadow: 0 1px 3px rgb(0 0 0 / 10%);
  font-size: 1.3rem !important;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  min-width: 55px;
}

.react-datepicker__header {
  padding-top: 6px !important;
  background-color: #FFFFFF;
  border-bottom: 0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-bottom-color: #F9F9F9;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before {
  border-top-color: #F9F9F9;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
  border-bottom-color: #FFFFFF;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after {
  border-top-color: #FFFFFF;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #223679;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #223679;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: 1.5rem !important;
}

.react-datepicker__navigation {
  top: 1px !important;
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  border-bottom-right-radius: 0;
}

.react-datepicker__day-name,
.react-datepicker__day {
  margin: 0.5rem !important;
}

.react-datepicker__time-container {
  width: 70px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 70px;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 70px;
}

/* Multiselect styles */

.multiselect,
.manual-data-input {
  width: 100%;
}

.multiselect .multiselect-header {
  background-color: #223679;
  color: #FFFFFF;
  padding: 5px 5px 5px 15px;
  border: 1px solid #223679;
  border-radius: 10px;
  width: 100%;
}

.multiselect .multiselect-hint {
  color: #767676;
  margin: 15px 5px 0;
}

.multiselect .multiselect-body,
.manual-data-input .manual-data-input-body {
  margin: 15px 5px 0;
  width: 100%;
}

.multiselect .multiselect-hint + .multiselect-body {
  margin-top: 0;
}

.multiselect .multiselect-select,
.manual-data-input .manual-data-input-select {
  width: 100%;
}

.multiselect .form-control,
.manual-data-input .form-control {
  padding: 6px 0 6px 12px;
}

.multiselect .selected-items,
.manual-data-input .selected-items {
  margin-top: 10px;
}

.multiselect .actions {
  width: 150px;
  margin-left: 10px;
}

.manual-data-input .actions {
  min-width: 84px;
  margin-bottom: 5px;
}

.multiselect .add,
.multiselect .delete,
.manual-data-input .add,
.manual-data-input .delete {
  width: 30px;
  margin: 6px;
  outline: 0;
  padding: 0;
}

.multiselect .error-message,
.manual-data-input .error-message {
  margin: 2px;
  color: #FF0000;
}

.multiselect .add .plus,
.manual-data-input .add .plus {
  position: absolute;
  top: 1px;
  left: 10px;
}

.multiselect .add,
.manual-data-input .add {
  position: relative;
  border-radius: 50%;
  box-shadow: 0;
  border: 0;
  font-size: 18px;
  font-weight: bold;
  height: 30px;
  background-color: #223679;
  color: #FFFFFF;
}

.multiselect .delete,
.manual-data-input .delete {
  border-radius: 50%;
  box-shadow: 0;
  border: 0;
  font-size: 12px;
  font-weight: bold;
  height: 30px;
  background-color: #CCCCCC;
  color: #FFFFFF;
}

.manual-data-input .manual-data-input-column {
  width: 100%;
  padding: 5px;
}

.manual-data-input .manual-data-input-column.short {
  width: unset;
  margin-right: 10px;
}

.manual-data-input .manual-data-input-column:not(.short) {
  width: 500px;
  max-width: 100%;
}

.calendar-svg {
  width: 20px;
  height: 20px;
  fill: #C0C0C0;
  position: absolute;
  top: 37px;
  left: 10px;
  z-index: 1;
}

.swal2-popup {
  font-size: 1.6rem !important;
}

.swal2-title {
  text-transform: capitalize !important;
}
.form-control {
  border-width: 1px !important;
}

.form-control[disabled] {
  appearance: none;
  background-color: #F9F9F9;
}

/* .table-component {
  width: 100%;
  overflow: auto;
  position: relative;
} */

/* .table-component table {
  table-layout: fixed;
} */

.table-component table > thead > tr > th {
  cursor: pointer;
  vertical-align: middle;
}

.table-component .icon-span,
.text-span {
  display: inline-block;
  vertical-align: middle;
}

.table-component .header-active {
  background: #223679;
  color: #FFFFFF;
}

.table-component .editable-input {
  width: 80px;
  padding: 5px;
  border-radius: 4px;
}

.json-modal-close {
  width: 200px;
  background-color: #223679;
  color: #FFFFFF;
}

.json-modal-close:hover,
.json-modal-close:focus {
  background-color: #0066CC;
  color: #FFFFFF;
}

.btn-default[disabled]:hover {
  background-color: #A5A5A5 !important;
  border-color: #A5A5A5 !important;
}

.actions-column.generate-password-column {
  flex-flow: row !important;
  flex-direction: row;
  padding-top: 15px;
}

.actions-column.generate-password-column button.toggle {
  border: 0;
  border-radius: 0;
  background: transparent;
  padding: 0;
  margin-right: 10px;
}

.actions-column.generate-password-column button.toggle img {
  width: 30px;
}

.twoFA-column:not(.merchant-settings) {
  padding-left: 30px;
}

.twoFA-column .wrap {
  display: flex;
}

.twoFA-column button.reset {
  position: relative;
  top: 5px;
  background-color: #223679;
  color: #FFFFFF;
  border-radius: 4px;
  border-width: 1px;
  padding: 10px 10px 11px;
  width: 130px;
}

.twoFA-column.user-settings button.reset {
  top: -5px;
  margin-left: 25px;
}

.react-tooltip {
  border: 1px solid #000000;
  border-radius: 3px;
  padding: 8px 21px !important;
  max-width: 500px;
  z-index: 2;
}

.react-tooltip-arrow {
  border-right: 1px solid #000000;
  border-bottom: 1px solid #000000;
}

code.json-view {
  padding: 0;
  font-size: 100%;
  background-color: unset;
  border-radius: unset;
}

.json-view .jv-indent {
  padding-left: 0;
}

.json-view .json-view--pair {
  padding: 3px 5px 3px 20px;
  border-left: 1px solid #EBEBEB;
}

.json-view  .json-view--property {
  color: #002B36;
}

.json-view  .json-view--string {
  color: #CB4B16;
  word-wrap: break-word;
}

.pagination {
  display: flex;
  justify-content: center;
}


.modal-dialog.iframe-modal {
  width: 100% !important;
  margin: 0;
}

.modal-dialog.iframe-modal .modal-content {
  border-radius: 0;
}

.modal-dialog.iframe-modal .modal-header {
  display: flex;
  align-items: center;
  padding: 7px 15px;
  background-color: #E5E5E5;
}

.modal-dialog.iframe-modal .modal-header .close-btn {
  margin-left: auto;
  background-color: #000000;
  color: #FFFFFF;
  border: 0;
  border-radius: 4px;
  padding: 5px 10px;
}

.modal-dialog.iframe-modal .modal-header .close-btn:hover {
  background-color: #001C6B;
}

.modal-dialog.iframe-modal .modal-header .modal-title {
  font-size: 14px;
}

.modal-dialog.iframe-modal .modal-header .modal-title span {
  font-size: 16px;
  font-style: italic;
  font-weight: bold;
}

.modal-dialog.iframe-modal .iframe-modal-content {
  padding: 0 !important;
}

/* View-only mode styles  */

.logged-as-user {
  position: fixed !important;
  top: 0;
  width: 100%;
  z-index: 10;
  align-items: center;
  background-color: #E5E5E5;
  padding: 7px 15px;
}

.logged-as-user .title {
  font-size: 14px;
  margin: 0;
}

.logged-as-user .title span {
  font-size: 16px;
  font-style: italic;
  font-weight: 700;
}

.logged-as-user .close-btn {
  background-color: #000000;
  border: 0;
  border-radius: 4px;
  color: #FFFFFF;
  margin-left: auto;
  padding: 5px 10px;
}

.content.view-only .navBar,
.content.view-only .navBar .burger-content {
  top: 44px;
}

.content.view-only .sidebar {
  padding-top: 104px;
}

.content.view-only .module .header {
  margin-top: 104px;
}

.content.view-only .navBar .group-icons .logout-icon,
.content.view-only .generateReports .panel-heading .settings-panel .setting,
.content.view-only .generateReports .panel-content td.history,
.content.view-only .generateReports .panel-content td button.action-button,
.content.view-only .my-account .btn.defaultBtn,
.content.view-only .sandbox .btn.defaultBtn,
.content.view-only .request-settlement .btn.defaultBtn,
.content.view-only .navBar .burger-content .logout-item .menuItem,
.content.view-only .settlements .amount-form .btn.defaultBtn,
.content.view-only .settlements .amount-form .checkbox-column label.container,
.content.view-only .settlements .running-balance-form .btn.defaultBtn,
.content.view-only .settlements .rb-form .btn.defaultBtn,
.content.view-only .settlements .running-balance-form .actions button,
.content.view-only .settlements .withheld-form .btn.defaultBtn,
.content.view-only .multiselect .actions,
.content.view-only .adminPanel .button-block .btn.defaultBtn,
.content.view-only .file-upload,
.content.view-only .bankCodes .account-row .actions,
.content.view-only .bankCodes .provider-accounts .btn.defaultBtn,
.content.view-only .bankCodes .provider-accounts .checkbox-column,
.content.view-only .bankCodes .provider-accounts .form-control,
.content.view-only .bankCodes .provider-accounts div[class*="control"],
.content.view-only .header .btn,
.content.view-only .psp-cascading .mid-priority-wrapper .toggler-container,
.content.view-only .psp-cascading .mid-priority-wrapper .td-priority,
.content.view-only .psp-cascading .prioritize label,
.content.view-only .psp-cascading .save-cascading .btn.defaultBtn,
.content.view-only .generateOrder .generate-order-actions .btn.defaultBtn,
.content.view-only .open-modal-btn,
.content.view-only .toggler-container,
.content.view-only .viewClient .button-block .btn.defaultBtn,
.content.view-only .editClient:not(.bankCodes) .btn.defaultBtn,
.content.view-only .user-management .btn.deactivate-user,
.content.view-only .user-management .twoFA-column.user-settings button.reset,
.content.view-only .user-management .login-as-btn,
.content.view-only .user-management .generate-password-column,
.content.view-only .editUser .btn.defaultBtn,
.content.view-only .blacklist .blacklist-table .remove-bl-account {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}

.content.view-only .my-account input.form-control {
  pointer-events: none;
  cursor: not-allowed;
  appearance: none;
  background-color: #F9F9F9;
}

/* Responsive styles */

@media screen and (width >= 992px) {
  .two-inputs-column {
    width: 65%;
  }

  .fxSettings .input-column {
    width: 220px;
  }

  .wrap-rule br {
    display: none;
  }

  .navBar .burger-container {
    display: none;
  }
}

@media screen and (width <= 1199px) {
  .two-inputs-column {
    width: 65%;
  }

  .fxSettings .input-column {
    width: 220px;
  }

  .wrap-rule br {
    display: none;
  }

  .addClient .extra-column {
    display: none !important;
  }

  .editClient .extra-column {
    display: none !important;
  }
}

@media screen and (width <= 991px) {
  .content .group-icons {
    display: none !important;
  }

  .navBar .burger-container {
    left: 15px;
    right: unset;
  }

  .navBar .burger-container.change {
    left: unset;
    right: 15px;
    top: 13px;
    z-index: 2;
  }

  .navBar .navBar-inner {
    justify-content: center !important;
  }

  .navBar .navBar-inner .logo-text {
    margin-left: 0;
  }

  .sidebarColumn {
    display: none !important;
  }

  .fxSettings .input-column {
    width: 100%;
    margin-right: 0;
  }

  .two-inputs-column {
    width: 100%;
  }

  .big-wrap-row {
    margin: 0;
  }

  .big-wrap-row img {
    max-width: 265px;
  }

  .two-inputs-column .input-column:first-of-type {
    margin-right: 10px;
  }
}

@media screen and (width <= 767px) {
  .fxFAQ .input-column:first-of-type {
    margin-right: 2%;
  }

  .multiselect .multiselect-select {
    width: 100%;
  }

  .modal-dialog.iframe-modal .modal-title,
  .modal-dialog.iframe-modal .modal-header .modal-title span {
    font-size: 14px;
  }

  .logged-as-user .title span {
    display: block;
  }
}

@media screen and (width <= 499px) {
  .module {
    height: 100%;
  }

  .empty-column {
    display: none !important;
  }

  .json-modal-close {
    width: 100%;
  }

  .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 75px;
  }
}
