.clients .panel-block {
  width: 100%;
  padding: 15px 15px 0;
}

.clients .input-column {
  width: 270px;
  margin-right: 3%;
  position: relative;
}

.clients .input-column input {
  padding-right: 30px;
}

.clients .input-column input[disabled],
.clients .input-column input[disabled] + .percent-sign {
  color: #979797;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

input[type='number'] {
  appearance: textfield;
}

.button-block {
  margin-bottom: 25px;
}

.button-block .btn {
  padding-left: 75px;
  padding-right: 75px;
}

.info-icon {
  margin-right: 3px;
}

.clients .clients-wrapper {
  width: 100%;
  overflow: auto;
  position: relative;
}

.clients table {
  width: 100%;
  margin-top: 15px;
}

.clients table thead tr {
  background-color: #223679;
  width: 100%;
  color: #FFFFFF;
}

.clients table th {
  padding: 8px;
}

.clients table th:nth-child(1) {
  border-radius: 6px 0 0 6px;
}

.clients table th:nth-last-child(1) {
  border-radius: 0 6px 6px 0;
}

.clients table td {
  word-wrap: break-word;
  vertical-align: middle;
}

.clients table .view-client {
  height: 35px;
  width: 70px;
  background-color: #3BE13B;
  border-radius: 5px;
  display: block;
  color: #FFFFFF;
  margin: auto;
  padding: 7px;
}

.clients table .view-client:hover {
  background-color: #223679;
}

.clients table .edit-client {
  height: 35px;
  width: 70px;
  background-color: #FFFFFF;
  border: 1px solid #D3D3D3;
  border-radius: 5px;
  padding: 7px;
  color: #000000;
  display: block;
  margin: auto;
}

.clients table .edit-client:hover {
  background-color: #223679;
  color: #FFFFFF;
}

.clients .search-bar {
  position: relative;
}

.clients .search-suggests {
  position: absolute;
  border: 1px solid #D3D3D3;
  border-radius: 5px;
  background-color: #FFFFFF;
  z-index: 10;
  top: 42px;
  width: 100%;
}

.clients .search-suggests .suggestion {
  padding: 8px;
  cursor: pointer;
}

.clients .search-suggests .suggestion:hover {
  background-color: #223679;
  color: #FFFFFF;
}

.clients .add-merchant-button {
  background-color: #223679;
  color: #FFFFFF;
  margin-right: 10px;
  font-size: 12px;
}

.clients .add-merchant-button.active {
  color: #FFFFFF;
}

.clients .add-merchant-button:hover {
  background-color: #0066CC;
  color: #FFFFFF;
}

.toggler-container {
  border: 2px solid rgb(93 93 93 / 13%) !important;
}

.dropdown-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 120px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 43px;
}

.dropdown-btn::after {
  content: "▾";
  font-size: 12px;
  color: #888;
  margin-left: 10px;
  flex-shrink: 0
}

.dropdown-btn:hover {
  border-color: #aaa;
}

.no-options {
  color: #888;
  text-align: center;
  padding: 8px;
  font-size: 14px;
}

.dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  padding: 10px;
  min-width: 230px;
  z-index: 1;
  max-height: 300px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #ccc #f9f9f9;
}

.dropdown-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
}

.panel-content .clients-wrapper .active-status {
  max-width: 100px;
  height: 43px;
}


.panel-content .clients-wrapper .active-status-edit {
  cursor: pointer;
  padding: 0 10px;
  line-height: 43px;
  min-width: 150px

}

@media screen and (width <= 500px) {
  .clients .clients-wrapper table thead > tr {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 50;
    display: block;
  }

  .clients .clients-wrapper table {
    width: auto;
  }

  .clients .clients-wrapper tbody {
    display: block;
  }

  .clients .clients-wrapper table tr td:first-child,
  .clients .clients-wrapper table tr th:first-child {
    position: sticky;
    left: 0;
    z-index: 40;
    background-color: inherit;
  }
}

@media screen and (width <= 600px) {
  .clients .clients-wrapper {
    width: calc(var(--vw, 1vw) * 100 - 30px);
  }

  .clients table td,
  .clients table th {
    min-width: 125px;
  }
}

@media screen and (width >= 501px) and (width <= 600px) {
  .clients table td:nth-child(1),
  .clients table th:nth-child(1) {
    position: sticky;
    left: -2px;
    z-index: 3;
    background-color: inherit;
  }
}
