.logs .header {
  width: 100%;
  background-color: #F9F9F9;
  height: 70px;
  margin-top: 60px;
}

.logs .input-column {
  width: 18%;
  min-width: 200px;
  margin: 10px;
}

.logs .input-column label {
  min-height: 20px;
}

.logs .table-column table > tbody > tr > td:last-child,
.logs .table-column table > thead > tr > th:last-child {
  text-align: end;
}

.logs .logs .panel-content {
  overflow: auto;
}

.logs .panel-content tbody td {
  border: 0;
}

.logs .logs-table {
  width: 100%;
  overflow: auto;
  margin-top: 15px;
  position: relative;
}

.logs .logs-table thead tr {
  background-color: #223679;
  width: 100%;
  color: #FFFFFF;
}

.logs .logs-table th {
  padding: 8px;
}

.logs .logs-table td {
  word-wrap: break-word;
  vertical-align: middle;
}

.logs .logs-table .open-modal-btn {
  padding: 0;
  border: 0;
  background: unset;
  text-decoration: underline;
  outline: none;
}


@media screen and (width <= 500px) {
  .logs .panel {
    max-width: calc(var(--vw, 1vw) * 100 + 10px) !important;
  }

  .logs .logs-table .table-component td,
  .logs .logs-table .table-component th {
    min-width: 150px;
  }

  .logs .logs-table .table {
    width: auto;
  }

  .logs .logs-table .table thead > tr {
    position: sticky;
    left: 0;
    z-index: 50;
    display: block;
  }

  .logs .logs-table .table tbody {
    display: block;
  }

  .logs .logs-table .table tr td:first-child,
  .logs .logs-table .table tr th:first-child {
    position: sticky;
    left: 0;
    z-index: 40;
    background-color: inherit;
  }
}

@media screen and (width <= 650px) {
  .logs .input-column {
    flex-grow: 1;
  }
}
