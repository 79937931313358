.adminPanel {
  width: 100%;
}

.adminPanel .input-column {
  min-width: 200px;
  max-width: unset;
}

.adminPanel .error-message {
  color: #FF0000;
}

.adminPanel .admin-table {
  min-width: 500px;
}

.adminPanel .admin-table tr th {
  border: 0;
}

.adminPanel .admin-table .td-toggler {
  width: 80px;
  border: 0;
  vertical-align: middle;
}

.adminPanel .admin-table .td-element {
  min-width: 150px;
  position: relative;
  vertical-align: middle;
}

.adminPanel .admin-table .td-disabled {
  position: relative;
  background-color: #F1F1F1;
}

.adminPanel .admin-table .td-error {
  border: 2px solid #FF0000;
}

.adminPanel .admin-table .table-input {
  width: 100%;
  border: 0;
  outline: 0;
  text-align: right;
}

.adminPanel .admin-table .td-actions {
  width: 110px;
}

.adminPanel .admin-table .add .plus {
  position: absolute;
  top: 1px;
  left: 10px;
}

.adminPanel .admin-table .add,
.delete {
  width: 30px;
  margin: 6px;
  outline: 0;
}

.adminPanel .admin-table .add {
  position: relative;
  border-radius: 50%;
  box-shadow: 0;
  border: 0;
  font-size: 18px;
  font-weight: bold;
  height: 30px;
  background-color: #223679;
  color: #FFFFFF;
}

.adminPanel .admin-table .delete {
  border-radius: 50%;
  box-shadow: 0;
  border: 0;
  font-size: 12px;
  font-weight: bold;
  height: 30px;
  background-color: #CCCCCC;
  color: #FFFFFF;
}

.adminPanel .single-select {
  margin-top: 25px;
}

.adminPanel .Select .Select-input {
  position: relative;
  width: calc(100% - 10px);
}

.adminPanel .Select .Select-input input {
  position: absolute;
  max-width: calc(100% - 10px);
}
