.login {
  margin-top: 60px;
  padding: 15px;
}

.loginPanel {
  width: 450px;
  margin: 0 auto;
}

.loginForm .inputWrap {
  margin-bottom: 15px;
}

.loginForm .inputWrap.codeWrap {
  margin-top: 20px;
}

.loginForm .defaultBtn {
  width: 100%;
}

.loginForm .recaptcha {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 20px 0 30px;
}

.loginForm iframe {
  width: 310px;
  height: 100px;
  margin: 0 auto;
}

.loginForm .two-fa-form img.qr-code {
  width: 150px;
  height: 150px;
  margin: 20px 0;
}

.loginForm .two-fa-form .buttons {
  display: flex;
  justify-content: space-around;
}

.loginForm .two-fa-form .buttons a.btn {
  margin-top: 15px;
  min-width: 120px;
  padding: 0;
  border-radius: 15px;
  background: transparent;
}

.loginForm .two-fa-form .buttons a.btn img {
  max-width: 150px;
}

.loginForm .inputWrap label {
  width: 100%;
}

.loginForm .inputWrap label .glyphicon {
  margin-left: 6px;
}

.loginForm .inputWrap label input {
  margin-top: 8px;
}

@media screen and (width <= 500px) {
  .loginPanel {
    width: 100%;
    margin: 0 auto;
  }

  .loginForm iframe {
    transform: scale(0.9);
  }
}

@media screen and (width >= 501px) and (width <= 1368px) {
  .loginForm iframe {
    width: 340px;
    height: 100px;
  }
}
