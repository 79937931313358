.currencyTable .fx-table-wrapper {
  width: 100%;
  overflow: auto;
  position: relative;
}

.currencyTable .fx-table {
  border-collapse: separate;
  border-spacing: 0.5vw;
  width: unset;
}

.currencyTable .fx-table tr th {
  border: 0;
}

.currencyTable .fx-table .td-toggler {
  width: 60px;
  border: 0;
}

.currencyTable .fx-table .td-element {
  position: relative;
  border: 1px solid #DDDDDD;
  padding-right: 25px;
  min-width: 60px;
}

.currencyTable .fx-table .td-error {
  border: 2px solid #FF0000;
}

.currencyTable .fx-table .td-disabled {
  position: relative;
  background-color: #F1F1F1;
}

.currencyTable .fx-table .td-disabled:not(:first-child) span {
  float: right;
}

.currencyTable .fx-table .table-input {
  width: 100%;
  border: 0;
  outline: 0;
}

.currencyTable .fx-table .percent-sign {
  position: absolute;
  top: 8px;
  right: 10px;
}

@media screen and (width <= 991px) {
  .currencyTable .fx-table {
    width: 100%;
  }
}

@media screen {
  .currencyTable .fx-table th:not(:first-child),
  .currencyTable .fx-table td:not(:first-child) {
    min-width: 175px !important;
  }

  .currencyTable .fx-table thead > tr {
    background-color: #FFFFFF;
  }
}

@media screen and (width >= 501px) and (width <= 3200px) {
  .currencyTable .fx-table td:nth-child(1),
  .currencyTable .fx-table th:nth-child(1) {
    position: sticky;
    left: 0;
    z-index: 3;
  }
}

@media screen and (width >= 701px) and (width <= 991px) {
  .currencyTable .fx-table-wrapper {
    width: calc(var(--vw, 1vw) * 100);
  }
}

@media screen and (width >= 501px) and (width <= 700px) {
  .currencyTable .fx-table-wrapper {
    width: calc(var(--vw, 1vw) * 100 - 20px);
  }
}

@media screen and (width >= 992px) and (width <= 3200px) {
  .currencyTable .fx-table-wrapper {
    width: calc(var(--vw, 1vw) * 100 - 185px);
  }
}
