.Loader,
.Loader::before,
.Loader::after {
  border-radius: 50%;
}

.Loader {
  color: #223679;
  font-size: 11px;
  text-indent: -99999em;
  margin: 20px auto;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  transform: translateZ(0);
}

.Loader.small {
  margin: -12px auto;
}

.Loader::before,
.Loader::after {
  position: absolute;
  content: '';
}

.Loader::before {
  width: 5.2em;
  height: 10.2em;
  background: #FFFFFF;
  border-radius: 10.2em 0 0 10.2em;
  top: -0.1em;
  left: -0.1em;
  transform-origin: 5.2em 5.1em;
  animation: load2 2s infinite ease 1.5s;
}

.Loader::after {
  width: 5.2em;
  height: 10.2em;
  background: #FFFFFF;
  border-radius: 0 10.2em 10.2em 0;
  top: -0.1em;
  left: 5.1em;
  transform-origin: 0 5.1em;
  animation: load2 2s infinite ease;
}

@keyframes load2 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes load2 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
