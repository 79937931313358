.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
}

.container input {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark,
.container input:checked ~ .checkmark {
  content: "";
  position: absolute;
  left: 0;
  top: 11px;
  width: 20px;
  height: 20px;
  border: 2px solid #CCCCCC;
  background: #FFFFFF;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px rgb(0 0 0 / 10%);
}

.checkmark[disabled] {
  cursor: not-allowed;
  appearance: none;
  opacity: 0.5;
}

.checkmark::after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark::after {
  display: block;
}

.container .checkmark::after {
  position: absolute;
  top: 1px;
  left: 1px;
  font-size: 0.8em;
  line-height: 0.8;
  color: #09AD7E;
  content: "\2713\0020";
  transition: all 0.2s;
  font-family: "Lucida Sans Unicode", "Arial Unicode MS", Arial, sans-serif !important;
}

.lol {
  display: inline-block;
}

.running-balance-form {
  width: 60%;
}

.amount-form .mid-row .input-column .txValue-inputMask {
  display: none;
}


button.delete {
  border-radius: 50%;
  box-shadow: none;
  border: 0;
  font-size: 12px;
  font-weight: bold;
  height: 30px;
  color: #FFFFFF;
  margin: 0 5px;
  background-color: #CCCCCC;
}

button.delete.debit {
  margin-top: 20px;
}

button.add {
  border-radius: 50%;
  box-shadow: none;
  border: 0;
  font-weight: bold;
  height: 30px;
  color: #FFFFFF;
  margin: 0 5px;
  width: 30px;
  position: relative;
  font-size: 18px;
  background-color: #223679;
}

button.add.debit {
  margin-top: 20px;
}

button.add.debit .plus {
  position: relative;
  top: -2px;
}

.input-column .line-separator {
  border: 1px solid #CCCCCC;
  width: 97%;
}

@media screen and (width >= 1300px) {
  .amount-form .mid-row:not(:first-child) .checkbox-column label.container {
    margin-top: -10px !important;
  }

  .amount-form .mid-row:not(:first-child) .input-column label:not(.container) {
    display: none;
  }

  .amount-form .mid-row .input-column label .txValue {
    display: none;
  }

  .amount-form .mid-row .input-column .txValue-inputMask {
    display: block;
    position: absolute;
    right: 12px;
    bottom: 11px;
    font-weight: bold;
  }

  .amount-form .mid-row .input-column .txValue-inputMask.full-width {
    width: 100%;
    height: 100%;
    right: unset;
    bottom: unset;
    padding: 6px 12px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    background-color: #EEEEEE;
    border: 1px solid #CCCCCC;
  }

  .amount-form .mid-row:first-child .input-column .txValue-inputMask.full-width {
    height: calc(100% - 25px);
    top: 25px;
  }
}

@media screen and (width <= 767px) {
  .running-balance-form {
    width: 100%;
  }
}
