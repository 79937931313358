.blacklist .header {
  width: 100%;
  background-color: #F9F9F9;
  height: 70px;
  margin-top: 60px;
}

.blacklist .input-column {
  width: 18%;
  min-width: 200px;
  margin: 10px;
}

.blacklist .input-column label {
  min-height: 20px;
}

.blacklist .table-column table > tbody > tr > td:last-child,
.blacklist .table-column table > thead > tr > th:last-child {
  text-align: end;
}

.blacklist .blacklist .panel-content {
  overflow: auto;
}

.blacklist .panel-content tbody td {
  border: 0;
}

.blacklist .add-user-button {
  background-color: #223679;
  color: #FFFFFF;
  margin-right: 10px;
  font-size: 12px;
}

.blacklist .upload-button {
  background-color: #223679;
  color: #FFFFFF;
  font-size: 12px;
}

.blacklist .add-user-button.active {
  color: #FFFFFF;
}

.blacklist .upload-button.active {
  color: #FFFFFF;
}

.blacklist .add-user-button:hover {
  background-color: #0066CC;
  color: #FFFFFF;
}

.blacklist .upload-button:hover {
  background-color: #0066CC;
  color: #FFFFFF;
}

.blacklist .blacklist-table {
  width: 100%;
  overflow: auto;
  margin-top: 15px;
  position: relative;
}

.blacklist .blacklist-table thead tr {
  background-color: #223679;
  width: 100%;
  color: #FFFFFF;
}

.blacklist .blacklist-table th {
  padding: 8px;
}

.blacklist .blacklist-table td {
  word-wrap: break-word;
  vertical-align: middle;
}

.blacklist .blacklist-table .remove-bl-account {
  height: 35px;
  width: 90px;
  background-color: #EAAA00;
  border-radius: 5px;
  padding: 7px;
  color: #FFFFFF;
  display: block;
  margin: auto;
}

.blacklist .blacklist-add-form {
  width: 100%;
  flex-direction: row;
  border-bottom: 1px solid #CCCCCC;
  padding: 15px 0;
}

.blacklist .blacklist-add-form:nth-last-child(2) {
  border-bottom: 0;
}

.blacklist .blacklist-add-form .input-column {
  width: unset;
}

.blacklist .blacklist-add-form .actions {
  padding-top: 20px;
}

.blacklist .blacklist-add-form .actions .delete,
.blacklist .blacklist-add-form .actions .add {
  width: 30px;
  height: 30px;
  margin: 6px;
  outline: 0;
  border-radius: 50%;
  box-shadow: none;
  border: 0;
  font-weight: bold;
  color: #FFFFFF;
}

.blacklist .blacklist-add-form .actions .delete {
  font-size: 12px;
  font-weight: bold;
  background-color: #CCCCCC;
}

.blacklist .blacklist-add-form .actions .add {
  position: relative;
  font-size: 18px;
  background-color: #223679;
}

.blacklist .blacklist-add-form .actions .add.add-hidden {
  visibility: hidden;
}

@media screen and (width >= 992px) {
  .blacklist .blacklist-add-form .input-column {
    min-width: unset;
  }
}

@media screen and (width <= 500px) {
  .blacklist .panel {
    max-width: calc(var(--vw, 1vw) * 100 + 10px) !important;
  }

  .blacklist .blacklist-table .table-component td,
  .blacklist .blacklist-table .table-component th {
    min-width: 150px;
  }

  .blacklist .blacklist-table .table {
    width: auto;
  }

  .blacklist .blacklist-table .table thead > tr {
    position: sticky;
    left: 0;
    z-index: 50;
    display: block;
  }

  .blacklist .blacklist-table .table tbody {
    display: block;
  }

  .blacklist .blacklist-table .table tr td:first-child,
  .blacklist .blacklist-table .table tr th:first-child {
    position: sticky;
    left: 0;
    z-index: 40;
    background-color: inherit;
  }
}

@media screen and (width <= 650px) {
  .blacklist .input-column {
    flex-grow: 1;
  }
}
