.generateOrder .input-column {
  min-width: 235px;
  width: calc(100% / 4 - 20px);
  max-width: calc(100% / 4 - 20px);
}

.generateOrder .input-column label {
  min-height: 20px;
}

@media screen and (width >= 992px) and (width <= 1199px) {
  .generateOrder .input-column {
    min-width: 185px;
  }
}

@media screen and (width >= 768px) and (width <= 991px) {
  .generateOrder .input-column {
    min-width: 175px;
  }
}

@media screen and (width <= 767px) {
  .generateOrder .input-column {
    width: 100%;
    max-width: unset;
  }
}
