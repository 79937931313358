.affiliate-links .header {
  width: 100%;
  background-color: #F9F9F9;
  height: 70px;
  margin-top: 60px;
}

.affiliate-links .input-column {
  width: 22%;
  position: relative;
  min-width: 130px;
  margin: 10px;
}

.affiliate-links .double-input-column {
  width: 44%;
  position: relative;
  min-width: 260px;
  margin: 10px;
}

.affiliate-links .panel-content tbody td {
  border: 0;
}

.link-input {
  padding-right: 35px !important;
}

.copy-icon {
  position: absolute;
  top: 35px;
  right: 10px;
  cursor: pointer;
}

@media screen and (width <= 600px) {
  .affiliate-links .input-column {
    width: 100% !important;
  }

  .affiliate-links .double-input-column {
    width: 100% !important;
  }
}
