.user-management .header {
  width: 100%;
  background-color: #F9F9F9;
  height: 70px;
  margin-top: 60px;
}

.user-management .input-column {
  position: relative;
  width: 270px;
  min-width: 130px;
  margin: 10px;
}

.user-management .balance-container {
  color: #FFFFFF;
  border-radius: 4px;
}

.user-management .input-column .percent-sign-complex {
  position: absolute;
  top: 35px;
  right: 10px;
}

.user-management .table-column table > tbody > tr > td:last-child,
.user-management .table-column table > thead > tr > th:last-child {
  text-align: end;
}

.user-management .user-management .panel-content {
  overflow: auto;
  position: relative;
}

.user-management .panel-content tbody td {
  border: 0;
}

.user-management .allSides {
  box-shadow: 0 0 20px  rgb(46 204 113 / 100%);
}

.user-management .add-deposit-button {
  background-color: #223679;
  color: #FFFFFF;
  margin-right: 10px;
  font-size: 12px;
  min-width: 150px;
}

.user-management .add-withdrawal-button {
  background-color: #223679;
  color: #FFFFFF;
  font-size: 12px;
  min-width: 150px;
}

.user-management .add-deposit-button.active {
  color: #FFFFFF;
}

.user-management .add-withdrawal-button.active {
  color: #FFFFFF;
}

.user-management .add-deposit-button:hover {
  background-color: #0066CC;
  color: #FFFFFF;
}

.user-management .add-withdrawal-button:hover {
  background-color: #0066CC;
  color: #FFFFFF;
}

.user-management .error-field {
  border: 2px solid #FF0000;
  border-radius: 7px;
}

.user-management .error-message {
  font-size: 14px;
  color: red;
  margin-top: 2px;
}

.user-management .user-radio:checked,
.user-radio:not(:checked) {
  position: absolute;
  left: -9999px;
}

.user-management .user-radio:checked + label,
.user-radio:not(:checked) + label {
  position: relative;
  padding-left: 35px;
  padding-top: 5px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666666;
}

.user-management .user-radio:checked + label::before,
.user-radio:not(:checked) + label::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 31px;
  height: 31px;
  border: 1px solid #CCCCCC;
  border-radius: 100%;
  background: #FFFFFF;
}

.user-management .user-radio:checked + label::after,
.user-radio:not(:checked) + label::after {
  content: '';
  width: 20px;
  height: 20px;
  background: #223679;
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 100%;
  transition: all 0.2s ease;
}

.user-management .user-radio:not(:checked) + label::after {
  opacity: 0;
  transform: scale(0);
}

.user-management .user-radio:checked + label::after {
  opacity: 1;
  transform: scale(1);
}

.user-management input[type="checkbox"] {
  position: absolute;
  top: 0;
  left: -9999px;
  visibility: hidden;
}

.user-management input[type="checkbox"]:checked + label::after {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.user-management .styled-input-single {
  position: relative;
  margin: 10px;
}

.user-management .styled-input-single label {
  cursor: pointer;
  margin-left: 35px;
  font-weight: normal;
}

.user-management .styled-input-single label.disabled {
  color: #CCCCCC;
  cursor: initial;
}

.user-management .styled-input-single label::before,
label::after {
  content: '';
  position: absolute;
  top: 50%;
  border-radius: 50%;
}

.user-management .styled-input-single label::before {
  left: 0;
  width: 25px;
  height: 25px;
  margin: -15px 0 0;
  background: #FFFFFF;

  /* box-shadow: 0 0 1px #808080;
  -webkit-appearance: none;
  -webkit-box-shadow: 0 0 1px #808080; */
  border: 1px solid #E0E0E0;
}

.user-management .styled-input-single label::after {
  left: 5px;
  width: 15px;
  height: 15px;
  margin: -10px 0 0;
  opacity: 0;
  background: #223679;
  transform: translate3d(-40px, 0, 0) scale(0.5);
  transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
}

.user-management .styled-input--square label::before,
label::after {
  border-radius: 0;
}

.user-management .user-management-calendar-icon {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1;
}

.user-management .day-item {
  margin: 5px;
}

.user-management .user-management-datetime-container .form-control[readonly] {
  background-color: #FFFFFF;
}

.user-management .Select.is-disabled > .Select-control {
  background-color: #EEEEEE;
}

.user-management .permission-separator {
  height: 2px;
  border-top: 1px solid #CCCCCC;
  width: 100%;
  margin-top: 3px;
}

.user-management .permission-header {
  font-weight: bold;
  color: #223679;
  margin-top: 15px;
}

.user-management table {
  width: 100%;
  margin-top: 15px;
}

.user-management table thead tr {
  background-color: #223679;
  width: 100%;
  color: #FFFFFF;
}

.user-management table th {
  padding: 8px;
}

.user-management table th:nth-child(1) {
  border-radius: 6px 0 0 6px;
}

.user-management table th:nth-last-child(1) {
  border-radius: 0 6px 6px 0;
}

.user-management table td {
  word-wrap: break-word;
  vertical-align: middle;
}

.user-management table .view-user {
  height: 35px;
  width: 70px;
  background-color: #3BE13B;
  border-radius: 5px;
  display: block;
  color: #FFFFFF;
  margin: auto;
  padding: 7px;
}

.user-management table .view-user:hover {
  background-color: #223679;
}

.user-management table .edit-user {
  height: 35px;
  width: 90px;
  background-color: #FFFFFF;
  border: 1px solid #D3D3D3;
  border-radius: 5px;
  padding: 7px;
  color: #000000;
  display: block;
  margin: auto;
}

.user-management table .activate-user {
  height: 35px;
  width: 90px;
  background-color: #3BE13B;
  border: 1px solid #D3D3D3;
  border-radius: 5px;
  padding: 7px;
  color: #FFFFFF;
  display: block;
  margin: auto;
}

.user-management table .deactivate-user {
  height: 35px;
  width: 90px;
  background-color: #EAAA00;
  border-radius: 5px;
  padding: 7px;
  color: #FFFFFF;
  display: block;
  margin: auto;
}

.user-management table .edit-user:hover {
  background-color: #223679;
  color: #FFFFFF;
}

.user-management .login-as-column label {
  visibility: hidden;
}

.user-management .login-as-column .login-as-btn {
  position: relative;
  top: -5px;
  color: #000000;
  background-color: #223679;
  color: #FFFFFF;
  border: 1px solid #D3D3D3;
  border-radius: 4px;
  padding: 10px 10px 11px;
}

@media (width >= 0) and (width <= 500px) {
  .user-management .add-deposit-button,
  .add-withdrawal-button {
    max-width: 100px;
  }

  .user-management .input-column {
    flex-grow: 1;
  }

  .user-management .user-management-wrapper .table thead > tr {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 50;
    display: block;
  }

  .user-management .user-management-wrapper .table tbody {
    display: block;
  }

  .user-management .user-management-wrapper .table tr td:first-child,
  .user-management .user-management-wrapper .table tr th:first-child {
    position: sticky;
    left: 0;
    z-index: 40;
    background-color: inherit;
  }
}

@media (width >= 501px) and (width <= 650px) {
  .user-management .user-management-table td:nth-child(1),
  .user-management .user-management-table th:nth-child(1) {
    position: sticky;
    left: -2px;
    z-index: 3;
    background-color: inherit;
  }
}

@media (width >= 0) and (width <= 650px) {
  .user-management .user-management-wrapper {
    width: calc(var(--vw, 1vw) * 100 - 20px);
    overflow: auto;
  }

  .user-management .user-management-table td,
  .user-management .user-management-table th {
    min-width: 160px;
    max-width: 160px;
  }
}
