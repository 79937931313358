.bankCodes .add-button {
  background-color: #223679;
  color: #FFFFFF;
  font-size: 12px;
}

.bankCodes .add-button:hover {
  background-color: #0066CC;
  color: #FFFFFF;
}

.bankCodes .provider-accounts {
  overflow: visible;
}

.bankCodes .provider-accounts .table th {
  color: #FFFFFF;
}

.bankCodes .checkbox-column {
  width: 0 !important;
}

.bankCodes .actions {
  margin-top: 25px;
}

.bankCodes label.file-upload {
  display: flex;
}

.bankCodes .file-upload .upload {
  background: #223679;
  color: #FFFFFF;
  padding: 11px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;
}

.bankCodes .file-upload .upload:hover {
  background: #0066CC;
}
