.main-sign-up {
  width: 100%;
  zoom: 0.9;
}

.signup-navbar {
  height: 80px;
  z-index: 9;
  margin-bottom: 20px;
  top: 0;
  position: fixed;
  background: linear-gradient(90deg, rgb(29 49 117) 20%, rgb(47 73 157) 100%);
}

.header-row {
  height: 100px;
}

.signup-rights {
  color: #FFFFFF;
  font-size: 16px;
  margin: 10px 20px;
  cursor: pointer;
}

.signup-rights:hover {
  text-decoration: underline;
}

.signup-footer {
  background-color: #2D2D2D;
  z-index: 2;
  width: 100%;
  min-height: 70px;
}

.signup-div {
  border-radius: 2rem;
  background-color: #FFFFFF;
  width: 80%;
  margin-top: 100px;
  margin-bottom: 10px;
  text-align: center;
  padding: 15px;
}

.signup-div h2 {
  font-weight: bold;
  font-size: 30px;
}

.signup-div h4 {
  font-size: 20px;
  line-height: 30px;
}

.signup-registration-button {
  margin: 3rem !important;
  width: 30%;
  min-width: 200px;
  font-weight: bold !important;
  font-size: 16px !important;
}

.signup-div hr {
  width: calc(100% - 40px);
  margin: 20px;
}

.signup-field {
  position: relative;
  margin: 12px 0;
}

.signup-field-label {
  position: absolute;
  top: -10px;
  left: 5px;
  background-color: #FFFFFF;
  text-align: left;
  padding: 0 10px 0 5px;
  color: #ADADAD;
  font-size: 14px;
  font-weight: normal;
}

.signup-input {
  height: 50px !important;
}

.error-field {
  border: 2px solid #FF0000 !important;
}

.error-message {
  font-size: 14px;
  color: red;
  margin-top: 2px;
}

.success-field {
  border: 2px solid #1BBF00 !important;
}

.signup-field-checkmark {
  position: absolute;
  right: 20px;
  top: 17px;
}

.signup-action-div .signup-action-header {
  text-align: center;
  color: #FFFFFF;
}

.signup-info .signup-info-row {
  margin: 0 12%;
  color: #FFFFFF;
  width: 76%;
}

.signup-general-info {
  margin: 40px 12%;
}

.signup-general-info .signup-general-info-row h1 {
  color: #223679;
}

.signup-info .signup-info-row li {
  font-size: 20px;
  padding-left: 35px;
  padding-bottom: 5px;
  background: url("../images/checkmark-landing.png") no-repeat left top;
}

.signup-general-info li {
  font-size: 20px;
  padding-left: 35px;
  padding-bottom: 5px;
  background: url("../images/checkmark-landing.png") no-repeat left top;
}

.signup-info .signup-info-row ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.signup-general-info ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.data-column {
  position: relative;
}

.modal-backdrop {
  background-color: #FFFFFF !important;
}

.modal-body {
  padding: 0 !important;
}

.modal-body ol {
  padding-left: 12px;
}

.modal-body ol li {
  font-size: 18px;
}

.modal-body ol h4 {
  margin-top: 5px;
}

.modal-column {
  width: 50%;
}

.modal-column > img {
  height: 100%;
}

.modal-column.fields {
  padding: 0 20px;
}

.register-header {
  color: #374049;
}

.close-modal {
  position: absolute;
  bottom: -40px;
  width: 100%;
}

.close-modal label {
  text-decoration: underline;
  cursor: pointer;
}

.close-modal label:hover {
  text-decoration: none;
}

.signup-bottom-button {
  width: 400px;
  margin: 10px;
}

/* PRODUCTS STYLES */

.agent .signup-action-button {
  width: 400px;
  height: 60px;
  margin: 10px;
  color: #FFFFFF;
  background-color: #63D311;
}

.agent .signup-info {
  background-image: url("../images/signup-agent/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 820px;
  width: 100%;
}

.signup-button-agent {
  background-color: #223679 !important;
  color: #FFFFFF !important;
  width: 100%;
  height: 50px;
}

.agent .signup-action-div {
  width: 100%;
  background: linear-gradient(0deg, rgb(34 54 121) 0%, rgb(16 68 181) 100%);
  min-height: 200px;
  padding: 20px;
}

.merchant .signup-action-button {
  width: 400px;
  height: 60px;
  margin: 10px;
  color: #FFFFFF;
  background-color: #F20074;
}

.merchant .signup-info {
  background-image: url("../images/signup-merchant/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 820px;
  width: 100%;
}

.signup-button-merchant {
  background-color: #F20074 !important;
  color: #FFFFFF !important;
  width: 100%;
  height: 60px;
  margin-bottom: 10px !important;
}

.merchant .signup-action-div {
  width: 100%;
  background-color: #1B1441;
  min-height: 200px;
  padding: 20px;
}

.merchant .app-image {
  position: absolute;
  bottom: -100px;
  left: -50px;
}

.white-label .signup-action-button {
  width: 400px;
  height: 60px;
  margin: 10px;
  color: #FFFFFF;
  background-color: #63D311;
}

.white-label .signup-info {
  background-image: url("../images/signup-agent/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 820px;
  width: 100%;
}

.signup-button-white-label {
  background-color: #223679 !important;
  color: #FFFFFF !important;
  width: 100%;
  height: 60px;
  margin-bottom: 10px !important;
}

.white-label .signup-action-div {
  width: 100%;
  background: linear-gradient(0deg, rgb(34 54 121) 0%, rgb(16 68 181) 100%);
  min-height: 200px;
  padding: 20px;
}

.white-label .app-image {
  position: absolute;
  bottom: -100px;
  left: -50px;
}

.psp .signup-action-button {
  width: 400px;
  height: 60px;
  margin: 10px;
  color: #FFFFFF;
  background-color: #F20074;
}

.psp .signup-info {
  background-image: url("../images/signup-psp/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 820px;
  width: 100%;
}

.signup-button-psp {
  background-color: #F20074 !important;
  color: #FFFFFF !important;
  width: 100%;
  height: 60px;
  margin-bottom: 10px !important;
}

.psp .signup-action-div {
  width: 100%;
  background-color: #1B1441;
  min-height: 200px;
  padding: 20px;
}

.psp video {
  zoom: 0.95;
}

.signup-burger-container {
  display: inline-block;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 13px;
}

.signup-burger-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 200px;
  top: 50px;
  right: 15px;
  border: 2px solid #CCCCCC;
  background-color: #FFFFFF;
  border-radius: 10px;
}

.signup-burger-content .link-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.signup-burger-content .link-item {
  display: block;
  width: 100%;
  margin: 5px 0;
  padding: 5px 5px 5px 15px;
  color: #223679;
  z-index: 5;
}

.signup-burger-content .link-item:hover {
  color: #FFFFFF;
  background-color: #223679;
}

.signup-burger-content .burger-bar,
.signup-burger-bar1,
.signup-burger-bar2,
.signup-burger-bar3 {
  width: 30px;
  height: 3px;
  background-color: #FFFFFF;
  margin: 6px 0;
  transition: 0.4s;
}

.signup-burger-change .signup-burger-bar1 {
  transform: rotate(-45deg) translate(-9px, 3px);
}

.signup-burger-change .signup-burger-bar2 {
  opacity: 0;
}

.signup-burger-change .signup-burger-bar3 {
  transform: rotate(45deg) translate(-8px, -5px);
}

@media screen and (width <= 1100px) {
  .signup-field {
    flex: 1 1 100%;
    max-width: 100%;
  }

  .signup-footer {
    justify-content: center !important;
  }

  .signup-info .signup-info-row {
    margin: 0 10px;
    width: 100%;
  }

  .agent .signup-info {
    background: #13398B !important;
  }

  .merchant .signup-info {
    background: #1A1541 !important;
  }

  .psp .signup-info {
    background: #120E33 !important;
  }

  .white-label .signup-info {
    background: #13398B !important;
  }

  .signup-general-info {
    margin: 0 10px;
  }

  .signup-footer .signup-rights {
    display: none;
  }

  .modal-column.backgrounded {
    display: none !important;
  }

  .data-column {
    padding: 0 15px;
  }

  .data-column img {
    width: 80%;
    height: auto;
  }

  .data-column video {
    width: 100%;
    height: auto;
  }

  .data-column .app-image {
    width: 40%;
    left: -10px;
    top: 50px;
  }

  .navigation-bar {
    display: none !important;
  }

  .signup-action-div {
    padding: 20px;
    font-size: 16px !important;
  }

  .signup-bottom-button {
    width: 75% !important;
  }

  .signup-action-button {
    width: 75% !important;
  }

  .signup-button-agent {
    margin-bottom: 10px !important;
  }
}

@media screen and (width >= 1001px) {
  .signup-burger-container {
    display: none;
  }
}

@media screen and (width >= 850px) {
  .modal-dialog {
    width: 800px !important;
  }
}

@media screen and (width <= 849px) {
  .modal-dialog {
    width: auto !important;
  }

  .data-column img {
    width: 100%;
    height: auto;
  }
}

@media screen and (width <= 1500px) {
  .signup-field {
    flex: 1 1 100%;
    max-width: 100%;
  }

  .data-row {
    flex-direction: column !important;
    justify-content: center !important;
  }

  .data-column {
    width: 100%;
    align-items: center !important;
    margin-bottom: 30px;
  }

  .psp .signup-info {
    background: #120E33 !important;
  }
}
